import * as React from "react";
import Layout from "../components/Layout/Layout";
import { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";

const EditHtmlContentBasicUsage = (props: { pageContext: any; }) => {
    const { pageContext } = props
  
    var siteJson = pageContext.site;
    if (siteJson == null) {
      siteJson = new Object();
    }
  
    var tenantJson = pageContext.tenant;
    if (tenantJson == null) {
      tenantJson = new Object();
    }

    let initialValue = 'HI THERE TEST';

    const editorRef = useRef(null);
    const [dirty, setDirty] = useState(false);
    useEffect(() => setDirty(false), [initialValue]);
    const save = () => {
      if (editorRef.current) {
        const content = editorRef.current.getContent();
        setDirty(false);
        editorRef.current.setDirty(false);
        // an application would save the editor content to the server here
        console.log(content);
      }
    };
  
    return (
        <Layout
            dynamicMenuItems={[]}
            pageTitle={'Edit HTML Content'}
            siteHeaderLogoImageUrl={siteJson.site_header_logo_image_url}
            siteTitle={siteJson.site_title}
            tenantId={tenantJson.tenant_id}
        >
            <Editor
                apiKey='s44n9wnih38lthnlq6qyp7emn06ruwcmo5p59rfyon2kqf9b'
                init={{
                    plugins: 'print preview paste importcss searchreplace autolink directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                    toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview print | insertfile image media template link anchor codesample | ltr rtl',
                    tinycomments_mode: 'embedded',
                    tinycomments_author: 'Author name',
                    mergetags_list: [
                    { value: 'First.Name', title: 'First Name' },
                    { value: 'Email', title: 'Email' },
                    ],
                    ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                }        
                }
                onInit={(evt, editor) => editorRef.current = editor}
                onDirty={() => setDirty(true)}
                initialValue={initialValue}
            />
                <button onClick={save} disabled={!dirty}>Save</button>
                {dirty && <p>You have unsaved content!</p>}
        </Layout>
    )
}
export default EditHtmlContentBasicUsage